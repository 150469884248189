export const fnCalculateHeights = (heights) => {
    try {
        const newHeights = heights.map(x => String(x).replace("px", ""))
        const response = newHeights.reduce(less)
        return (String(response) + "px");
    } catch (error) {
        console.error('fnCalculateHeights => ', error)
        return 0
    }
}

const less = (total, num) => +total - +num;