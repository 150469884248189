import { useContext } from "react"
import { Snackbar, Alert } from "@mui/material/"
import { SnackbarContext } from "context/Snackbar/SnackbarContext"

const TaSnackbar = () => {

  const { sbState: { isOpen, variant, message }, sbDispatch: { close } } = useContext(SnackbarContext)
  const onClose = () => close()

  return (
    isOpen && <Snackbar
      open={true}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert
        id={'infoMsg'}
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={variant}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default TaSnackbar